html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
	min-height: auto;
	-webkit-text-size-adjust: 100%;
	font-size: var(--font-size);
}

* {
	scroll-margin: var(--spacer);
	
	@media (pointer: fine) {
		scroll-margin: calc(var(--spacer) * 3);
		scroll-margin: 35svh;
	}
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

.infopage {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
}

.columns-masonry {
  column-width: calc(150px + 10vmax);
  column-gap: var(--column-gap);
	
	& > * {
		box-sizing: border-box;
		break-inside: avoid-column;
		
		width: 100%;
		height: 100%;
	}
}

.columns-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(min((150px + 10vmin), 100%), 1fr));
	gap: var(--column-gap);
	
	& > * {
		width: 100%;
	}
}

section + section {
	margin-top: var(--spacer-block-with-fallback);
}

.section {	
	& > *:not(.comp-card) + * {
		margin-top: var(--spacer-padding);
	}	
}

.tag {
	display: inline-block;
	position: relative;
	font-size: .75rem;
	font-weight: 800;
	line-height: 1.25em;
	padding: var(--tags-padding-block) var(--tags-padding-inline);
	opacity: var(--opacity-medium);
	
	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: var(--border-radius);
		vertical-align: baseline;
		translate: 0% -3%;
		border: .175em rgba(var(--black-values)) solid;
	}
}

.link-button {
	display: inline-block;
	background-color: var(--black);
	color: var(--background-color, var(--white));
	padding: calc(var(--tags-padding-block) * 4) calc(var(--tags-padding-inline) * 5);
	border-radius: var(--border-radius);
	// optical correction
	margin-top: calc(var(--spacer-em-half) * 2);
	width: min(34ch, 100%);
	/* width: 100%; */
	box-sizing: border-box;
	text-align: center;
	font-size: var(--font-size-headline-bodytext);
}

.add-divider-line {
	position: relative;

	& + .add-divider-line:before {
		
		content: "";
		display: block;
		position: absolute;
		height: var(--border-strenght);
		width: 100%;
		opacity: var(--opacity-very-low);
		/* translate: 0 calc(var(--spacer) / -2); */
		
		background: linear-gradient(to right, var(--black) 60%, transparent 0%) top repeat-x;
		background-size: var(--length-dashes) var(--border-strenght), var(--border-strenght) var(--length-dashes);
		background-position: center;
	}
}

.divider-line {
	display: block;
	height: var(--border-strenght);
	width: 100%;
	opacity: var(--opacity-very-low);
	/* translate: 0 calc(var(--spacer) / -2); */
	
	background: linear-gradient(to right, var(--black) 60%, transparent 0%) top repeat-x;
	background-size: var(--length-dashes) var(--border-strenght), var(--border-strenght) var(--length-dashes);
	background-position: center;
}

ul {
    margin: 0;
    padding: 0;
    margin-block: 0.75em;
}

.package-section {
    background-color: var(--background-color, white);
    padding: max(var(--spacer), var(--padding-outside)) var(--padding-outside);
    border-radius: var(--border-radius);
    font-size: var(--font-size-headline-bodytext);
	
	&.coming-soon {
		padding-top: calc(max(var(--spacer), var(--padding-outside)) * .85);
	}
  }

  .page {
    margin-top: var(--hero-half-screen-offset);
	
	&.page-home {
		--offeset-preheadline: calc(-2em - var(--spacer-padding));
		--offeset-preheadline: calc(-2lh - var(--spacer-padding));

		margin-top: calc(var(--hero-half-screen-offset) + var(--offeset-preheadline));
	}
  }